
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {},
  "entity": {
    "id": 1,
    "name": "AutopartsPro Démo",
    "uniqueReference": "AUTOPARTSPRO",
    "siret": "0000000000",
    "creationDate": "2023-11-03T08:41:18.86",
    "isActivated": true,
    "isDisabled": false,
    "deletedDate": null
  },
  "establishment": {
    "id": 1,
    "entityId": 1,
    "templateId": 1,
    "themeId": 2,
    "simultaneousUsersConnexion": 4,
    "stateMode": 2,
    "dateProduction": "2023-02-01T00:00:00",
    "erpOrderOrigin": "AUTOPARTSPRO",
    "isCheckStockBeforeOrder": true,
    "deleted": false,
    "comments": "Site AutopartsPro DEV",
    "fullUrl": "https://dev-app.autopartspro.fr",
    "hostDomain": "dev-app.autopartspro.fr",
    "hostIp": "127.0.0.1",
    "logoImg": "",
    "logoImgAltern": null,
    "logoText": null,
    "name": "AutoPartsPro",
    "companyName": "AutoPartsPro",
    "companyPublishManager": "Franck Dartus",
    "companyPublishForm": "SASU Société par actions simplifiée à associé unique",
    "companyRcs": "XXXXXXX",
    "companySiret": "XXXXXXX",
    "companyTva": "XXXXXXX",
    "htSite": true,
    "slogan": "AutoPartsPro",
    "describeShort": "AutoPartsPro",
    "describeFull": "AutoPartsPro",
    "footerText": "AutoPartsPro",
    "horaires": "Tous les vendredi de 8h à 18h",
    "metaTitle": "AutoPartsPro",
    "metaDescribe": "AutoPartsPro",
    "metaKeywords": "AutoPartsPro",
    "metaSharedImage": null,
    "metaFavIcon": null,
    "metaAuthor": "AutoPartsPro",
    "metaAppName": null,
    "metaSubject": "AutoPartsPro",
    "analyticsKey": "000000",
    "linkedInAccount": null,
    "facebookAccount": "AutoPartsPro",
    "instagramAccount": "AutoPartsPro",
    "whatsappAccount": null,
    "twitterAccount": "AutoPartsPro",
    "youtubeAccount": "AutoPartsPro",
    "daylimotionAccount": "AutoPartsPro",
    "twitchAccount": "AutoPartsPro",
    "createDate": "2023-01-10T11:18:09.973",
    "createUserId": null,
    "createUserIp": null,
    "createUserBrowser": null,
    "updateDate": null,
    "updateUserId": null,
    "updateUserIp": null,
    "updateUserBrowser": null,
    "deletedDate": null,
    "deletedUserId": null,
    "deletedUserIp": null,
    "deletedUserBrowser": null,
    "personIdEcommerce": null,
    "personIdSav": null,
    "personId": null,
    "isImportMerchantExternalOrders": false,
    "isOrdersDataSourceFromErp": false,
    "sendOrdersToErpOn": true,
    "showOnlyMerchantBrands": true,
    "deliveryAddressModifyUrl": null,
    "erpCodeClientWeb": null,
    "isConsigneIncluded": true,
    "configuration": {
      "id": 1,
      "establishmentId": 1,
      "defaultTaxId": 1,
      "erpOrderOrigin": "AUTOPARTSPRO_DEV",
      "erpCodeClientWeb": null,
      "simultaneousUsersConnexion": 4,
      "isCheckStockBeforeOrder": true,
      "isOrdersDataSourceFromErp": false,
      "isImportMerchantExternalOrders": false,
      "deliveryAddressModifyUrl": null,
      "showOnlyMerchantBrands": true,
      "sendOrdersToErpOn": true,
      "isConsigneIncluded": true,
      "isDevis": true,
      "isWarehousemanResearch": false,
      "isVehicleMaintenance": null,
      "isManualRepair": null,
      "isTechnicalData": null,
      "isGraphicResearch": true,
      "isTechnicalCharacteristicsParts": false,
      "isProfessionalAdvice": false,
      "isShop": true,
      "isCart": true,
      "isPneumatic": true,
      "showAvailabilityStock": true,
      "showQuantityStock": true,
      "sessionTime": 24,
      "showBranchName": true,
      "showAutopartsproLogo": true,
      "isDiscount": true,
      "isPublicPrices": true,
      "isActiveMddTecdocBrands": null,
      "smtpMainId": 1,
      "smtpQuoteId": 1,
      "mediasUrl": "https://medias.eitedelinternational.com",
      "technicalDataLicenceType": "4",
      "technicalDataLicenceDuration": "M",
      "warningMaintenanceText": null,
      "createAccountText": "<p><span class=font-semibold>Félicitations</span>, votre nouveau compte client a bien été créé !<p>Veuillez noter que celui-ci est maintenant en attente d'activation par nos services.<p>Nous vous informerons par mail sur l'adresse <span class=font-semibold>« ${email} »</span> dès qu'il sera disponible.<p>Merci pour votre demande et à très bientôt.",
      "tax": {
        "id": 1,
        "countryId": 1001,
        "title": "20%",
        "percentage": 20,
        "isActive": true
      },
      "mainSmtp": {
        "id": 1,
        "title": "APP Main",
        "description": "Utilsation principale AutoPartsPro",
        "ip": "mail.mailfr.biz",
        "port": 587,
        "login": "no-reply@mailfr.biz",
        "password": "Tre1272@",
        "email": "no-reply@mailfr.biz",
        "sslActive": true,
        "tlsActive": false,
        "forceSenderName": null,
        "forceReplyTo": null,
        "isOn": true,
        "dateCreated": null
      },
      "quoteSmtp": {
        "id": 1,
        "title": "APP Main",
        "description": "Utilsation principale AutoPartsPro",
        "ip": "mail.mailfr.biz",
        "port": 587,
        "login": "no-reply@mailfr.biz",
        "password": "Tre1272@",
        "email": "no-reply@mailfr.biz",
        "sslActive": true,
        "tlsActive": false,
        "forceSenderName": null,
        "forceReplyTo": null,
        "isOn": true,
        "dateCreated": null
      }
    },
    "phone": "0562678820",
    "siteGarageUrl": "https://dev-garages.mecanobox.fr/",
    "email": "contact@autopartspro.fr"
  },
  "suppliers": [
    {
      "id": 1,
      "typeId": 2,
      "typeTitle": "Catalogue pièce auto",
      "title": "TECDOC Pegasus 3.0",
      "description": "Webservice TECDOC Pegasus 3.0",
      "catalog": null,
      "frontCallEndPoint": "https://wstdc.autopartspro.fr",
      "devFrontCallEndPoint": "https://wstdc.autopartspro.fr",
      "dateCreated": "2023-10-13T17:10:28.923",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 2,
      "typeId": 3,
      "typeTitle": "Données techniques",
      "title": "TECRMI",
      "description": "Webservice TecRMI",
      "catalog": null,
      "frontCallEndPoint": "https://wsrmi.autopartspro.fr",
      "devFrontCallEndPoint": "https://dev-wsrmi.autopartspro.fr",
      "dateCreated": "2023-10-13T17:10:54.1",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 13,
      "typeId": 5,
      "typeTitle": "SIV (Plaques)",
      "title": "AAA Slvin",
      "description": "Webservice AAA Slvin",
      "catalog": null,
      "frontCallEndPoint": "https://wspl.autopartspro.fr",
      "devFrontCallEndPoint": "https://wspl.autopartspro.fr",
      "dateCreated": "2023-10-18T17:24:02.56",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 11,
      "typeId": 6,
      "typeTitle": "Service de l'application",
      "title": "ShoppingCart",
      "description": "Panier et commandes",
      "catalog": null,
      "frontCallEndPoint": "https://wscart.autopartspro.fr",
      "devFrontCallEndPoint": "https://wscart.autopartspro.fr",
      "dateCreated": "2024-02-01T00:00:00",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    },
    {
      "id": 14,
      "typeId": 7,
      "typeTitle": "SIV (VIN)",
      "title": "TECRMI + TECDOC Pegasus 3.0",
      "description": "TECRMI + TECDOC Pegasus 3.0",
      "catalog": null,
      "frontCallEndPoint": "https://wspl.autopartspro.fr",
      "devFrontCallEndPoint": "https://wspl.autopartspro.fr",
      "dateCreated": "2023-10-13T17:10:28.923",
      "rank": 0,
      "isOn": true,
      "isDeleted": false,
      "deletedDate": null
    }
  ],
  "terms": [
    {
      "id": 10,
      "idEstablishment": 1,
      "idCategory": 1,
      "idSubCategory": 1,
      "isActive": true,
      "term": "Disponibles en magasin",
      "subCategory": {
        "id": 1,
        "name": "Disponible",
        "isActive": true
      },
      "category": {
        "id": 1,
        "name": "Disponibilités",
        "isActive": true
      }
    },
    {
      "id": 11,
      "idEstablishment": 1,
      "idCategory": 1,
      "idSubCategory": 2,
      "isActive": true,
      "term": "Disponibles J+1",
      "subCategory": {
        "id": 2,
        "name": "Disponible avec délais",
        "isActive": true
      },
      "category": {
        "id": 1,
        "name": "Disponibilités",
        "isActive": true
      }
    },
    {
      "id": 12,
      "idEstablishment": 1,
      "idCategory": 1,
      "idSubCategory": 3,
      "isActive": true,
      "term": "Disponibles logistique",
      "subCategory": {
        "id": 3,
        "name": "Disponible entrepôt",
        "isActive": true
      },
      "category": {
        "id": 1,
        "name": "Disponibilités",
        "isActive": true
      }
    }
  ],
  "links": {
    "cdnUrl": "https://cdn.autopartspro.fr"
  },
  "themeApp": {
    "light": {
      "mainColor": "#00A09B",
      "secondaryColor": "#111521",
      "terciaryColor": "#FFE073",
      "neutral23": "#3B393D",
      "shades76": "#C6F1EB",
      "neutral45": "#727272",
      "neutral71": "#B5B5B5",
      "neutral77": "#C7C3C3",
      "shades94": "#F0FAF8",
      "borderBtnWhite": "#DDE2E8",
      "btnSearch": "#00A09B",
      "btnLinear": "#00A09B",
      "background97": "#F9F8F9",
      "roundedBorder": "0.5rem",
      "dispo": "",
      "dispoSoon": "",
      "indispo": "",
      "inLogistics": "",
      "defaultFontColor": "#FFFFFF"
    },
    "dark": {}
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
