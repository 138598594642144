import { default as indexEvHevGKPLDMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/custom/index.vue?macro=true";
import { default as packagesRqdwVeRNrjMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/custom/packages.vue?macro=true";
import { default as partssnVxjAfWCJMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/custom/parts.vue?macro=true";
import { default as customeYPNCGZpTsMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/custom.vue?macro=true";
import { default as indexkp8tX9wHsCMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/index.vue?macro=true";
import { default as ratesywo1IAt6BLMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/rates.vue?macro=true";
import { default as subscription9e2nr8OEuTMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/subscription.vue?macro=true";
import { default as accountxoV8zp19G8Meta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account.vue?macro=true";
import { default as _91id_93pomJ1pqEUIMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexozG8etnqpVMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/clients/index.vue?macro=true";
import { default as _91id_938PrIxRSIXqMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93y5dlzTiKfuMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/commandes/[id].vue?macro=true";
import { default as index2DDsyuXypxMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/commandes/index.vue?macro=true";
import { default as vehicules9U0ZSRxgF0Meta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_93m0IiboW1oCMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/boutique/[title]-[id].vue?macro=true";
import { default as indexbqtW5KRBBeMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/boutique/index.vue?macro=true";
import { default as productsv54EVlX6nhMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/boutique/products.vue?macro=true";
import { default as indexNZfQdupyidMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/index.vue?macro=true";
import { default as configuration2gpDeEkuWvMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexl4G1c0rHrLMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance/index.vue?macro=true";
import { default as planacbldA0ewBMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance/plan.vue?macro=true";
import { default as worksvodv2reuevMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenance2cBBshaQ8HMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance.vue?macro=true";
import { default as manualsbrNX6wwzIlMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/manuals.vue?macro=true";
import { default as timesfXpxTdKobVMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/times.vue?macro=true";
import { default as cataloguhKwjlbj8bMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog.vue?macro=true";
import { default as commandeRUJdFDl5QmMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/commande.vue?macro=true";
import { default as _91tab_934gdVP6npXCMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/devis/[tab].vue?macro=true";
import { default as _91id_93Lg1LgqyrVZMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93Ml5mHEuMk6Meta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfqZGqybBdtUMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/imprimer-pdf.vue?macro=true";
import { default as indexIHcRbmpNmPMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/index.vue?macro=true";
import { default as loginH5GGUK7QMeMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/login.vue?macro=true";
import { default as panierZ3C6vGcsQDMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/panier.vue?macro=true";
import { default as indexazlBC6mHDOMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/pneumatique/index.vue?macro=true";
import { default as productsHeyJz5xg3pMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiquebUJDCn5wZJMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/pneumatique.vue?macro=true";
import { default as maintenance5obebBFXD5Meta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/print/maintenance.vue?macro=true";
import { default as productsQWMw8PYpEeMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/products.vue?macro=true";
import { default as signup2CbkS5NMlSMeta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordmxKo4ywia9Meta } from "/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountxoV8zp19G8Meta?.name,
    path: "/account",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account.vue"),
    children: [
  {
    name: customeYPNCGZpTsMeta?.name,
    path: "custom",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/activite/vehicules.vue")
  },
  {
    name: "boutique-title-id",
    path: "/boutique/:title()-:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/boutique/[title]-[id].vue")
  },
  {
    name: "boutique",
    path: "/boutique",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/boutique/index.vue")
  },
  {
    name: "boutique-products",
    path: "/boutique/products",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/boutique/products.vue")
  },
  {
    name: cataloguhKwjlbj8bMeta?.name,
    path: "/catalog",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/index.vue")
  },
  {
    name: maintenance2cBBshaQ8HMeta?.name,
    path: "maintenance",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfqZGqybBdtUMeta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginH5GGUK7QMeMeta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/panier.vue")
  },
  {
    name: pneumatiquebUJDCn5wZJMeta?.name,
    path: "/pneumatique",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenance5obebBFXD5Meta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup2CbkS5NMlSMeta || {},
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("/var/www/vhosts/autopartspro.fr/dev-app.autopartspro.fr/pages/tableau-de-bord.vue")
  }
]